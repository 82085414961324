// css files
import 'bootstrap/dist/css/bootstrap.min.css';
import "./SocialIcons.css"
import './Navbar.css'

// components
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Col, Image, Row} from "react-bootstrap";

// my components
import SocialIcons from "./SocialIcons";

// icons, images
import {faTimes, faBars, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons";
import logo from "../assets/images/logo-removebg.png";


function Navbar() {
    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <div className='menu-icon' onClick={handleClick}>
                        <FontAwesomeIcon icon={click ? faTimes : faBars} size="2x"/>
                    </div>

                    <Row>
                        <Col>
                            <a
                                href="/"
                                className={click ? 'navbar-logo active' : 'navbar-logo'}>
                                <Image src={logo} width={110} style={{marginLeft: "10px"}}/>
                            </a>
                        </Col>

                        <Col className="social-icon-align">
                            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
                                  integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
                                  crossOrigin="anonymous"/>

                            <ul id="test">
                                <li id="test2">
                                    <a id="test3" onClick={() => openInNewTab('https://www.facebook.com/A-Z-Stavba-108127138093991')}>
                                        <FontAwesomeIcon icon={faFacebookF} size="1x"/>
                                    </a>
                                </li>
                                <li id="test2">
                                    <a id="test3" onClick={() => openInNewTab('https://www.instagram.com/az_stavba_/')}>
                                        <FontAwesomeIcon icon={faInstagram} size="1x"/>
                                    </a>
                                </li>
                                <li id="test2">
                                    <a id="test3" href="mailto:azstavba@centrum.sk">
                                        <FontAwesomeIcon id="test4" className="social-icon-center" icon={faEnvelope} size="1x"/>
                                    </a>
                                </li>
                                <li id="test2">
                                    <a id="test3" href="tel:+421 904 351 410">
                                        <FontAwesomeIcon id="test4" icon={faPhone} size="1x"/>
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item-custom'>
                            <a
                                href='/#services'
                                className='nav-links'
                                onClick={() => {
                                    closeMobileMenu()
                                }}>
                                SLUŽBY
                            </a>
                        </li>
                        <li className='nav-item-custom'>
                            <a
                                href='/#realizations'
                                className='nav-links'
                                onClick={() => {
                                    closeMobileMenu()
                                }}>
                                GALÉRIA
                            </a>
                        </li>
                        <li className='nav-item-custom'>
                            <a
                                href='/#cooperation'
                                className='nav-links'
                                onClick={() => {
                                    closeMobileMenu()
                                }}>
                                SPOLUPRÁCA
                            </a>
                        </li>
                        <li className='nav-item-custom'>
                            <a
                                href='/#contact'
                                className='nav-links'
                                onClick={() => {
                                    closeMobileMenu()
                                }}>
                                KONTAKT
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar
