// css files
import '../../App.css';
import '../styles/HowOrder.css';

// components
import React from "react";
import {Container, Row} from "react-bootstrap";

// own components
import SendEmail from "../SendEmail";

export default function HowOrder () {
    return (
        <>
            <div className="container-height-auto padding-bottom-100 lighter-theme">
                <div className="vertical-align">
                    <Container>
                        <div id='order'/>
                        <Row>
                            <div className="header text-start">PREDBEŽNÁ OBJEDNÁVKA</div>
                        </Row>
                        <Row>
                            <SendEmail/>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
