// css files
import "./Cooperation.css"
import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";
import ItemsCarousel from "react-items-carousel";
import kundrex from "../../assets/images/cooperation/KUNDREX.jpg";
import umareka from "../../assets/images/cooperation/Asas u Mareka.png";
import laster from "../../assets/images/cooperation/LASTER s.r.o.png";
import ceramik from "../../assets/images/cooperation/CERAMIK.png";
import byt_interier from "../../assets/images/cooperation/Byt Interiér.png";
import klampplast from "../../assets/images/cooperation/KLAMPPlast.png";
import km_slovakia from "../../assets/images/cooperation/KM Slovakia.png";
import energotip from "../../assets/images/cooperation/ENERGOTIP.png";
import komal from "../../assets/images/cooperation/KOMAL.png";
import porotherm from "../../assets/images/cooperation/Porotherm.png";
import rambet from "../../assets/images/cooperation/RAMBET.jpg";
import sklo_kali from "../../assets/images/cooperation/SKLO-KALI.png";
import stylkozvan from "../../assets/images/cooperation/STYLKOZVAN.png";
import vexta from "../../assets/images/cooperation/VEXTA ofis.png";
import ytong from "../../assets/images/cooperation/Ytong.png";
import zelma from "../../assets/images/cooperation/ZELMA.png";

export default function MyItemsCarousels(props) {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const kundrex_web = 'https://kundrex.webnode.sk/';
    const umareka_web = 'https://www.umareka.sk/';
    const laster_web = 'https://laster.sk/';
    const ceramik_web = 'http://ceramik.sk/';

    const byt_interier_web = 'http://byt-interier.szm.sk/kuchyna.html';
    const km_slovakia_web = 'https://svietidla-km.sk/';
    const rambet_web = 'http://rambet.sk/';
    const stylkozvan_web = 'https://www.kozuby-vano.sk/';
    const komal_web = 'https://www.facebook.com/komalsro.stavebniny';
    const vexta_web = 'http://www.vexta.sk/';
    const ytong_web = 'https://eshop.ytong.sk/';
    const energotip_web = 'https://www.energotip.sk/';
    const sklokali_web = 'https://sklokali.sk/';
    const porotherm_web = 'https://www.wienerberger.sk/';

    return (
        <div className="text-center carousel-width">
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={props.numberOfCards}
                gutter={props.gutter}
                leftChevron={<Button className="btn-warning carousel-button"><FontAwesomeIcon icon={faCaretLeft}/></Button>}
                rightChevron={<Button className="btn-warning carousel-button"><FontAwesomeIcon icon={faCaretRight}/></Button>}
                chevronWidth={props.chevronWidth}
                outsideChevron
                >
                <a onClick={() => openInNewTab(kundrex_web)} className="carousel-icon">
                    <img src={kundrex} height={120} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(ceramik_web)} className="carousel-icon carousel-item-width">
                    <img src={klampplast} alt="Logo" className="carousel-item-width"/>
                </a>
                <a onClick={() => openInNewTab(umareka_web)} className="carousel-icon">
                    <img src={umareka} height={120} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(ceramik_web)} className="carousel-icon carousel-item-width">
                    <img src={zelma} height={120} alt="Logo" className="carousel-item-width carousel-item-long-logos"/>
                </a>
                <a onClick={() => openInNewTab(laster_web)} className="carousel-icon">
                    <img src={laster} height={120} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(ceramik_web)} className="carousel-icon carousel-item-width">
                    <img src={ceramik} height={55} className="carousel-item-width carousel-item-long-logos" alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(byt_interier_web)} className="carousel-icon">
                    <img src={byt_interier} height={120} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(km_slovakia_web)} className="carousel-icon">
                    <img src={km_slovakia} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(porotherm_web)} className="carousel-icon">
                    <img src={porotherm} height={120} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(rambet_web)} className="carousel-icon carousel-item-width">
                    <img src={rambet} height={75} alt="Logo" className="carousel-item-width" />
                </a>
                <a onClick={() => openInNewTab(stylkozvan_web)} className="carousel-icon">
                    <img src={stylkozvan} height={120} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(komal_web)} className="carousel-icon">
                    <img src={komal} height={90} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(vexta_web)} className="carousel-icon">
                    <img src={vexta} height={120} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(sklokali_web)} className="carousel-icon carousel-item-width">
                    <img src={sklo_kali} height={75} alt="Logo" className="carousel-item-width carousel-item-long-logos"/>
                </a>
                <a onClick={() => openInNewTab(ytong_web)} className="carousel-icon">
                    <img src={ytong} height={120} alt="Logo"/>
                </a>
                <a onClick={() => openInNewTab(energotip_web)} className="carousel-icon carousel-item-width">
                    <img src={energotip} height={90} alt="Logo" className="carousel-item-width carousel-item-long-logos"/>
                </a>
            </ItemsCarousel>
        </div>
    );
}