// css files
import 'react-toastify/dist/ReactToastify.css';
import "react-toastify/dist/ReactToastify.min.css";
import "../App.css"

// components
import React, {useState} from "react";
import {Button, ButtonGroup, Card, Col, DropdownButton, Form, FormControl, InputGroup, Modal, Row, Table} from "react-bootstrap";
import {faArrowCircleUp, faEnvelope, faGlobeAmericas, faMap, faPaperPlane, faUser, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify';
import DropdownItem from "react-bootstrap/DropdownItem";

function Example(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let notify = () => toast.info("Vaša správa bola odoslaná", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    const handleSubmitClose = () => {
        props.handleSubmit()
        setShow(false)
        notify()
    }

    return (
        <>
            <Button
                onClick={handleShow}
                className="btn-secondary width-100p height-60">
                <span className="upload-button">ODOSLAŤ SPRÁVU</span>
                <FontAwesomeIcon icon={faPaperPlane} size="2x"/>
            </Button>

            <Modal
                id="agreeModal"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>ODOSLAŤ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Chcete nám odoslať Vaše informácie o Vašej objednávke ? S našou ponukou sa Vám ozveme do 72 hodín.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Zrušiť
                    </Button>
                    <Button variant="dark" onClick={handleSubmitClose} onSubmit={handleClose}>
                        Odoslať
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rooms: 'penis',
            name: '',
            email: '',
            area: '',
            place: '',
            message: '',
            selectedFile: [],
            stack: []
        };
        this.inputReference = React.createRef();
    }

    fileUploadButton = () => {
        document.getElementById('fileButton').click();
        document.getElementById('fileButton').onchange = () =>{
            this.setState({
                fileUploadState:document.getElementById('fileButton').value
            });
        }
    }

    // On file select (from the pop up)
    onFileChange = event => {
        this.state.stack.push(event.target.files[0])
        // Update the state
        this.setState({selectedFile: this.state.stack});
    };

    // File content to be displayed after
    // file upload is complete
    fileData = () => {
        if (this.state.selectedFile) {
            let filesNames = ""
            let fileSize
            let fileNum = 0
            let heightOffset = 160
            for (const file of this.state.selectedFile) {
                fileSize = Math.round(file.size / 1024)
                if (fileSize > 1000) {
                    fileSize = Math.round((fileSize / 1024) * 100) / 100 + " MB"
                } else {
                    fileSize = fileSize + " KB"
                }

                let postfix = file.name.split(".").pop();
                fileNum++
                filesNames = filesNames +
                    '<tr><td style="text-align: center">' + fileNum + '</td>' +
                    '<td>' + file.name + '</td>' +
                    '<td>' + postfix + '</td>' +
                    '<td>' + fileSize + '</td></tr>'
                document.getElementById("wrapper").innerHTML = filesNames
                document.getElementById("upload-container").style.height = heightOffset + 'px'
                heightOffset += 45
            }

            return (
                <div id="upload-container">
                    <br/>

                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th width="80">Súbor</th>
                                <th>Meno</th>
                                <th width="60">Typ</th>
                                <th width="100">Veľkosť</th>
                            </tr>
                        </thead>
                        <tbody id="wrapper"/>
                    </Table>

                    <br/>
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                </div>
            );
        }
    };

    handleChangeName = (event) => {
        this.setState({name: event.target.value});
    }

    handleChangeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    handleChangeArea = (event) => {
        this.setState({area: event.target.value});
    }

    handleChangePlace = (event) => {
        this.setState({place: event.target.value});
    }

    handleChangeMessage = (event) => {
        this.setState({message: event.target.value});
    }

    handleSubmit = (event) => {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        let i = 0;
        for (const file of this.state.selectedFile) {
            i++
            formData.append(
                "file"+i,
                file
            );
        }

        const json = JSON.stringify(this.state);
        const blob = new Blob([json], {
            type: 'application/json'
        });
        formData.append("document", blob);

        fetch('http://127.0.0.1:8000/', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: formData,
        }).then(function(response) {
            console.log(response)
        });
    }

    notify = () => toast.info("Vaša správa bola odoslaná", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    render() {
        function handleDesign() {
            handleTypeOfWork("design")
            let elem = document.getElementById("design")
            let dropDown = document.getElementById("bg-nested-dropdown")

            if (elem.classList.contains("header-type-of-work")) {
                dropDown.removeAttribute("disabled")
            } else {
                dropDown.setAttribute("disabled", "true")
            }
        }

        function handleBuild() {
            handleTypeOfWork("build")
        }

        function handleTypeOfWork(id) {
            let elem = document.getElementById(id)
            let elem2 = document.getElementById(id + "2")
            let msgField = document.getElementById(id + "-msg")
            let areaField = document.getElementById(id + "-area")

            if (elem.classList.contains("header-type-of-work")) {
                elem.classList.remove("header-type-of-work")
                elem2.classList.remove("border-type-of-work")
                msgField.setAttribute("disabled", "true")
                areaField.setAttribute("disabled", "true")
            } else {
                elem.classList.add("header-type-of-work")
                elem2.classList.add("border-type-of-work")
                msgField.removeAttribute("disabled")
                areaField.removeAttribute("disabled")
            }

        }

        function addRoom1() {
            roomBoxField("Kuchyňa")
        }

        function addRoom2() {
            roomBoxField("Jedálen")
        }

        function addRoom3() {
            roomBoxField("Obývacia izba")
        }

        function addRoom4() {
            roomBoxField("Kúpelňa")
        }

        function addRoom5() {
            roomBoxField("Spálňa")
        }

        function addRoom6() {
            roomBoxField("Detská izba")
        }

        function addRoom7() {
            roomBoxField("Pracovňa")
        }

        function addRoom8() {
            roomBoxField("Hosťovská izba")
        }

        function roomBoxField(room) {
            let div = document.getElementById("added_rooms")
            if (div === null) {
                div = document.createElement("div")
                div.setAttribute("id", "added_rooms")
                div.classList.add("pr-20")
                let colDiv = document.getElementById("col_added_rooms")
                colDiv.appendChild(div)
            }
            let tag = document.createElement("span");
            tag.setAttribute("name", "room")
            let text;

            if (div.hasChildNodes()) {
                text = document.createTextNode( ", " + room);
                tag.appendChild(text);
            } else {
                text = document.createTextNode(room);
                tag.appendChild(text);
            }

            div.appendChild(tag);
        }

        function deleteRooms() {
            let div = document.getElementById("added_rooms");
            div.remove()
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <InputGroup className="mb-3" size={"lg"}>
                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faUser} className="icon-size-15"/></InputGroup.Text>
                        <FormControl
                            placeholder="VAŠE MENO A PRIEZVISKO"
                            aria-label="name"
                            value={this.state.value}
                            onChange={this.handleChangeName}
                        />
                    </InputGroup>
                </Row>
                <Row>
                    <InputGroup className="mb-3" size={"lg"}>
                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} className="icon-size-15"/></InputGroup.Text>
                        <FormControl
                            placeholder="VAŠA E-MAILOVA ADRESA"
                            aria-label="email"
                            value={this.state.value}
                            onChange={this.handleChangeEmail}
                            required
                        />
                        <FormControl.Feedback type="invalid">
                            Prosím vypĺňte email
                        </FormControl.Feedback>
                    </InputGroup>
                </Row>
                <Row>
                    <InputGroup className="mb-3" size={"lg"}>
                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faGlobeAmericas} className="icon-size-15"/></InputGroup.Text>
                        <FormControl
                            placeholder="KDE SA NEHNUTEĽNOSŤ NACHÁDZA"
                            aria-label="place"
                            aria-describedby="basic-addon1"
                            value={this.state.value}
                            onChange={this.handleChangePlace}
                        />
                    </InputGroup>
                </Row>
                <br/>
                <Row>
                    <div className="title">
                        MÁM ZÁUJEM
                    </div>
                </Row>
                <Row>
                    <Col className="mr-20">
                        {handleDesign}
                        <Card id="design2">
                            <Card.Header id="design" onClick={handleDesign}>
                                <span className="text-l-bold">Interierový design</span>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMap} className="icon-size-15"/></InputGroup.Text>
                                        <FormControl
                                            id="design-area"
                                            placeholder="RIEŠENÁ PLOCHA V M2"
                                            aria-label="area"
                                            value={this.state.value}
                                            onChange={this.handleChangeArea}
                                            required
                                            disabled
                                        />
                                        <FormControl.Feedback type="invalid">
                                            Prosím vypĺňte plochu
                                        </FormControl.Feedback>
                                    </InputGroup>
                                </Row>
                                <Row>
                                    <span className="sub-title">Projektová dokumentácia</span>
                                </Row>
                                <div key="inline" className="mb-4">
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Check
                                                inline
                                                label="áno"
                                                name="group1"
                                                type="radio"
                                                id={`inline-1`}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                inline
                                                label="nie"
                                                name="group1"
                                                type="radio"
                                                id={`inline-2`}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <div className="sub-title">
                                        Riešené miestnosti
                                    </div>
                                </Row>
                                <Row className="mb-1">
                                    <Col xs={3}>
                                        <ButtonGroup>
                                            <DropdownButton
                                                as={ButtonGroup}
                                                title="+ Pridať "
                                                id="bg-nested-dropdown">
                                                <DropdownItem onClick={addRoom1} eventKey="1">Kuchyňa</DropdownItem>
                                                <DropdownItem onClick={addRoom2} eventKey="2">Jedáleň</DropdownItem>
                                                <DropdownItem onClick={addRoom3} eventKey="2">Obývacia izba</DropdownItem>
                                                <DropdownItem onClick={addRoom4} eventKey="2">Kúpelňa</DropdownItem>
                                                <DropdownItem onClick={addRoom5} eventKey="2">Spálňa</DropdownItem>
                                                <DropdownItem onClick={addRoom6} eventKey="2">Detská izba</DropdownItem>
                                                <DropdownItem onClick={addRoom7} eventKey="2">Pracovňa</DropdownItem>
                                                <DropdownItem onClick={addRoom8} eventKey="2">Hosťovská izba</DropdownItem>
                                            </DropdownButton>
                                        </ButtonGroup>
                                    </Col>
                                    <Col xs={8} id="col_added_rooms">
                                        <div id="added_rooms" className="pr-20"/>
                                    </Col>
                                    <Col xs={1}>
                                        <Button id="bg-nested-dropdown" onClick={deleteRooms}><FontAwesomeIcon icon={faTimes}/></Button>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <div className="sub-title">
                                        Vaša správa
                                    </div>
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} className="icon-size-15"/></InputGroup.Text>
                                        <FormControl
                                            id="design-msg"
                                            rows={5}
                                            placeholder="Správa"
                                            as="textarea"
                                            value={this.state.value}
                                            onChange={this.handleChangeArea}
                                            required
                                            disabled
                                        />
                                        <FormControl.Feedback type="invalid">
                                            Prosím vypĺňte email
                                        </FormControl.Feedback>
                                    </InputGroup>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card id="build2">
                            <Card.Header id="build" onClick={handleBuild}>
                                <span className="text-l-bold">Stavebné práce</span>
                            </Card.Header>
                            <Card.Body>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Row>
                                            <Col xs={3}>
                                                <Form.Check
                                                    inline
                                                    label="o návrh"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Check
                                                    inline
                                                    label="o návrh aj realizáciu"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <div className="sub-title">
                                        STAV BYTU
                                    </div>
                                </Row>

                                {['radio'].map((type) => (
                                    <Row key={`inline-${type}`} className="mb-3">
                                        <Col>
                                            <Form.Check
                                                inline
                                                label="byt v štandarde"
                                                name="group2"
                                                type={type}
                                                id={`inline-${type}-1`}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                inline
                                                label="čiastočná rekonštrukcia"
                                                name="group2"
                                                type={type}
                                                id={`inline-${type}-2`}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                inline
                                                label="celková rekonštrukcia"
                                                name="group2"
                                                type={type}
                                                id={`inline-${type}-2`}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                inline
                                                label="holobyt"
                                                name="group2"
                                                type={type}
                                                id={`inline-${type}-2`}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                    <div className="title">
                        VLOŽIŤ PRÍLOHY
                    </div>
                    <div>
                        <Button onClick={this.fileUploadButton} className="btn-secondary width-100p height-60">
                            <span className="upload-button">Nahrať</span> <FontAwesomeIcon icon={faArrowCircleUp} size="2x"/>
                        </Button>
                        <input id="fileButton"
                               type="file"
                               onChange={this.onFileChange}
                               hidden
                        />
                        {this.fileData()}
                        <br/>
                    </div>
                </Row>
                <Row>
                    <div className="title">
                        ODOSLAŤ INFORMÁCIE
                    </div>
                    <Col>
                        <Row>
                            <Example
                                notification={this.notify}
                                handleSubmit={this.handleSubmit}/>
                        </Row>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover={false}/>
                    </Col>
                </Row>
            </Form>
        );
    };
}
