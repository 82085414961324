// css files
import "./../Contact.css"

// components
import React, {useState} from "react";
import {Button, Col, Container, Form, FormControl, InputGroup, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faComments, faEnvelope, faPaperPlane, faPhone, faUser} from "@fortawesome/free-solid-svg-icons";
import {toast, ToastContainer} from "react-toastify";

const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a className="a-href-color" href={`mailto:${email}${params}`}>{children}</a>;
};

function Example(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let notify = () => toast.info("Vaša správa bola odoslaná", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    const handleSubmitClose = () => {
        props.handleSubmit()
        setShow(false)
        notify()
    }

    return (
        <>
            <Button
                onClick={handleShow}
                className="send-button-contact width-100p height-60">
                <span className="upload-button">ODOSLAŤ</span>
                <FontAwesomeIcon icon={faPaperPlane} className="paper-plane-size"/>
            </Button>

            <Modal
                className="toaster-width"
                id="agreeModal"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>ODOSLAŤ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{padding: '10px'}}>
                        Chcete nám odoslať Vaše informácie o Vašej objednávke ? S našou ponukou sa Vám ozveme do 72 hodín.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Zrušiť
                    </Button>
                    <Button variant="dark" onClick={handleSubmitClose} onSubmit={handleClose}>
                        Odoslať
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            area: '',
            place: '',
            message: '',
            selectedFile: [],
            stack: [],
            proj: true
        };
        this.inputReference = React.createRef();
    }

    handleChangeName = (event) => {
        this.setState({name: event.target.value});
    }

    handleChangeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    handleChangeMessage = (event) => {
        this.setState({message: event.target.value});
    }

    handleSubmit = () => {
        // Create an object of formData
        const formData = new FormData();

        const json = JSON.stringify(this.state);
        const blob = new Blob([json], {
            type: 'application/json'
        });
        formData.append("document", blob);

        console.log(formData)
        fetch('http://127.0.0.1:5000/', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(json),
        }).then(function(response) {
            console.log(response)
        });
    }

    notify = () => toast.info("Vaša správa bola odoslaná", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    render () {
        return (
            <>
                <div className="container-height-auto lighter-theme">
                    <div className='vertical-align'>
                        <Container>
                            <br/><br/>
                            <div id='contact'/>
                            <Row>
                                <span  className="header text-start">
                                    Kontakt
                                </span>
                            </Row>
                            <Row className="contact-font-size">
                                <Col>
                                    <span className="contact-company">
                                        A-Z stavba
                                    </span><span className="contact-company-sro"> s.r.o</span><br/><br/>
                                    <span className="contact-info">
                                        <FontAwesomeIcon id="bullet" icon={faCircleNotch} className="mr-20"/>
                                        Dubová 2714/6
                                    </span><br/>
                                    <span className="contact-info">
                                        <FontAwesomeIcon id="bullet" icon={faCircleNotch} className="mr-20 mt-1"/>
                                        085 01, Bardejov
                                    </span><br/><br/>
                                    <span className="contact-info ">
                                        <FontAwesomeIcon icon={faEnvelope} className="mr-20"/>
                                        <Mailto email="azstavba@centrum.sk" subject="Hello & Welcome" body="Hello world!">
                                            azstavba@centrum.sk
                                        </Mailto>
                                    </span><br/>
                                    <span className="contact-info">
                                        <FontAwesomeIcon icon={faPhone} className="mr-20 mt-1"/>
                                        <a className="a-href-color" href="tel:+421 904 351 410">+421 904 351 410</a>
                                    </span><br/><br/>
                                    <span className="contact-info">
                                        <FontAwesomeIcon id="bullet" icon={faCircleNotch} className="mr-20"/>
                                        IČO: 47 342 331
                                    </span><br/>
                                    <span className="contact-info">
                                        <FontAwesomeIcon id="bullet" icon={faCircleNotch} className="mr-20 mt-1"/>
                                        IČ DPH: SK2023843140
                                        <br/><br/>
                                    </span>
                                </Col>
                                <Col>
                                    <div className="title">
                                        Máte otázky? Napíšte nám!
                                    </div>
                                    <Form
                                        onSubmit={this.handleSubmit}>
                                        <InputGroup className="mb-3" size={"md"}>
                                            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faUser}
                                                                                                size={"2x"}/></InputGroup.Text>
                                            <FormControl
                                                placeholder="VAŠE MENO A PRIEZVISKO"
                                                aria-label="name"
                                                value={this.state.value}
                                                onChange={this.handleChangeName}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3" size={"md"}>
                                            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faEnvelope}
                                                                                                size={"2x"}/></InputGroup.Text>
                                            <FormControl
                                                placeholder="VAŠA E-MAILOVA ADRESA"
                                                aria-label="email"
                                                value={this.state.value}
                                                onChange={this.handleChangeEmail}
                                                required
                                            />
                                            <FormControl.Feedback type="invalid">
                                                Prosím vypĺňte email
                                            </FormControl.Feedback>
                                        </InputGroup>
                                        <InputGroup className="mb-3" size={"md"} style={{fontWeight: 900}}>
                                            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faComments} size={"2x"}/></InputGroup.Text>
                                            <FormControl
                                                placeholder="SPRÁVA"
                                                as="textarea"
                                                aria-label="message"
                                                className="height-150"
                                                value={this.state.value}
                                                onChange={this.handleChangeMessage}
                                            />
                                        </InputGroup>
                                        <br/>
                                        <Col>
                                            <Example
                                                notification={this.notify}
                                                handleSubmit={this.handleSubmit}/>
                                            <ToastContainer
                                                position="top-right"
                                                autoClose={5000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover={false}/>
                                        </Col>
                                    </Form>
                                </Col>
                            </Row>
                            <br/><br/><br/>
                        </Container>
                    </div>
                </div>
            </>
        )
    }
}
