// css files
import '../../App.css';
import '../styles/Services.css';
import {Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import './About.css';
import React from "react";
import about from "../../assets/images/about.JPG";
import realization2 from "../../assets/images/realizacie/domy/007.jpg";
import {InView} from "react-intersection-observer";

export default function About() {

    function animation_left_photo(inView) {
        if (inView) {
            document.getElementById("about-text").classList.add("show-animation");
        } else {
            document.getElementById("about-text").classList.remove("show-animation");
        }
    }

    return (
        <>
            <div id="container" className="container-height-auto lighter-theme">
                <div className="vertical-align">
                    <Container>
                        <br/><br/>
                        <div id='about'/>
                        <Row>
                            <div className="center-proposal about-text">
                                <span className="title-about">
                                    A-Z STAVBA s.r.o
                                </span><br/>
                                <span className="about-line">
                                    Stavebné práce od A-Z.
                                </span><br/>
                                <span className="about-line">
                                    Naša stavebná spoločnosť je na trhu od roku 2011.
                                </span><br/>
                                <span className="about-line">
                                    Počas týchto rokov sme nabrali veľa skúsenosti, poučili sa zo svojich ale aj chýb druhých,
                                </span><br/>
                                <span className="about-line">
                                    aby  sme práve Vám poskytli tie najlepšie služby.
                                </span><br/>
                                <span className="about-line">
                                    Ako moderná stavebná firma si kladieme za cieľ najmä kvalitu, odbornosť  a spokojnosť zákazníka.
                                </span><br/>
                                <span className="about-line">
                                    Našimi zákazníkmi sú hlavne stavitelia / investori v Bardejove a okolí, ale aj občania, ktorí
                                </span><br/>
                                <span className="about-line">
                                    potrebujú postaviť, zrekonštruovať nehnuteľnosť, prípadne urobiť nejaké menšie prácena dome.
                                </span><br/>
                                <span className="about-line">
                                    Zabezpečíme pre vás kompletnú realizáciu stavby ale aj dokončovacie práce v interiéri a exteriéri.
                                </span>
                            </div>
                        </Row>
                        <br/><br/>
                    </Container>
                </div>
                <div className="about-container">
                    <Container>
                        <Row>
                            <Col md={3} className="left-card" style={{padding: '0'}}>
                                <div className="left-card-container">
                                    <InView as="div" onChange={(inView, entry) => animation_left_photo(inView)}>
                                        <div id="about-text">
                                            "<br/>
                                            S nami<br/>
                                            sa vaše sny<br/>
                                            stanú<br/>
                                            skutočnosťou.<br/>
                                            "
                                        </div>
                                    </InView>
                                </div>
                            </Col>
                            <Col md={9} style={{padding: '0'}}>
                                <img src={about} alt="Logo" className="image"/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
