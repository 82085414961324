// css files
import './Cooperation.css'

// components
import {React, useState} from "react";
import {Button, Container, Row} from "react-bootstrap";
import ItemsCarousel from 'react-items-carousel';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";

// icons, images
import MyItemsCarousels from "./MyItemsCarousels";

export default function Cooperation() {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <div className="container-height-auto darker-theme remove-bottom-padding">
                <div className='vertical-align'>
                    <Container>
                        <div id='cooperation'/>
                        <div className="header text-start">
                            Naši partneri
                        </div>
                        <div className="text-xl text-center">
                            K našim realizáciám neodmysliteľne patria spoľahliví partneri a dodávatelia.
                        </div><br/><br/>
                        <Row>
                            <div className="text-center numOfCards5">
                                <MyItemsCarousels
                                    numberOfCards={5}
                                    gutter={20}
                                    chevronWidth={100}
                                    />
                            </div>
                            <div className="text-center numOfCards4">
                                <MyItemsCarousels
                                    numberOfCards={4}
                                    gutter={16}
                                    chevronWidth={100}
                                    />
                            </div>
                            <div className="text-center numOfCards3">
                                <MyItemsCarousels
                                    numberOfCards={3}
                                    gutter={11}
                                    chevronWidth={100}
                                    />
                            </div>
                            <div className="text-center numOfCards2">
                                <MyItemsCarousels
                                    numberOfCards={2}
                                    gutter={5}
                                    chevronWidth={100}
                                    />
                            </div>
                            <div className="text-center numOfCards1">
                                <MyItemsCarousels
                                    numberOfCards={1}
                                    gutter={1}
                                    chevronWidth={10}
                                    />
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
