// css files
import "./Contact.css"

// components
import React from "react";
import {Col, Form, FormControl, InputGroup, Row,} from "react-bootstrap";

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            area: '',
            place: '',
            message: '',
            selectedFile: [],
            stack: [],
            proj: true
        };
        this.inputReference = React.createRef();
    }

    handleChangeName = (event) => {
        this.setState({name: event.target.value});
    }

    handleChangeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    handleChangeArea = (event) => {
        this.setState({area: event.target.value});
    }

    handleChangePlace = (event) => {
        this.setState({place: event.target.value});
    }

    handleChangeMessage = (event) => {
        this.setState({message: event.target.value});
    }

    handleSubmit = (event) => {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        let i = 0;
        for (const file of this.state.selectedFile) {
            i++
            formData.append(
                "file"+i,
                file
            );
        }

        const json = JSON.stringify(this.state);
        const blob = new Blob([json], {
            type: 'application/json'
        });
        formData.append("document", blob);

        fetch('http://127.0.0.1:8000/', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: formData,
        }).then(function(response) {
            console.log(response)
        });
    }

    calculatePrice = () => {
        let calculator = document.querySelector("#calculator");
        let price = document.querySelector("#price");
        let doc = document.querySelector("#doc");
        let area = calculator.value;

        if (doc.value === "true") {
            if (area < 80) {
                price.value = 12 * area + "€"
            } else {
                price.value = 14 * area + "€"
            }
        } else {
            if (area < 80) {
                price.value = 7 * area + "€"
            } else {
                price.value = 9 * area + "€"
            }
        }
    }

    render () {
        return (
            <>
                <Row>
                    <Col>
                        <div className="title">
                            CHCEM INTERIÉR
                        </div>
                        <div className="text-center">
                            Zaujíma Vás cena za návrh? Zadajte do prvého krúžku približnú plochu priestoru (m2) a dozviete sa orientačnú sumu.
                            V prípade záujmu Vám radi vypracujeme nezáväznú cenovú ponuku presne podľa Vašich potrieb.
                            Stačí vyplniť dopytový formulár.
                        </div>
                    </Col>
                </Row>
                <br/><br/>
                <Row style={{textAlign: '-webkit-center'}}>
                    <Col xl={3} md={6} sm={12}>
                        <Row>
                            <Col>
                                <div className="circle text-center vertical-align ml-50">
                                    <InputGroup className="mb-3 calculator-width" size={"lg"}>
                                        <FormControl
                                            id="calculator"
                                            placeholder="Plocha"
                                            aria-label="area"
                                            value={this.state.value}
                                            onChange={this.calculatePrice}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={3} md={6} sm={12}>
                        <div className="circle circle-dropdown text-center ml-50">
                            <br/>
                            <Form.Select
                                id="doc"
                                onChange={this.calculatePrice}>
                                <option value="false">Bez dokumentácie</option>
                                <option value="true">S dokumentáciou</option>
                            </Form.Select>
                        </div>
                    </Col>
                    <Col xl={3} md={6} sm={12}>
                        <div className="circle text-center vertical-align ml-50">
                            <InputGroup className="mb-3 calculator-width" size={"lg"}>
                                <FormControl
                                    id="price"
                                    placeholder="Cena"
                                    aria-label="area"
                                    value={this.state.value}
                                    onChange={this.handleChangeEmail}
                                    disabled
                                />
                            </InputGroup>
                        </div>
                    </Col>
                    <Col xl={3} md={6} sm={12}>
                        <a
                            href="/#contact"
                            className="circle text-center vertical-align ml-50 a-href-color text-l">
                            Zistiť viac
                        </a>
                    </Col>
                </Row>
            </>
        )
    }
}
