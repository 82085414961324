// css files
import '../../App.css';
import '../styles/Services.css';

// components
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { InView } from 'react-intersection-observer';
import React, {useState} from "react";
import {Button, Card, Col, Collapse, Container, Row, Tab, Tabs} from "react-bootstrap";
import {faChevronDown, faChevronUp, faCircle, faCouch, faHammer,} from "@fortawesome/free-solid-svg-icons";

// own components
import realization from "../../assets/images/realizacie/progress/003.jpg";
import realization2 from "../../assets/images/realizacie/domy/007.jpg";
import Calculator from "../Calculator";

// photos
import drobne_objekty from "../../assets/icons/Drobné objekty.png";
import exterierove_prace from "../../assets/icons/Exteriérové práce.png";
import hruba_stavba from "../../assets/icons/Hrubá stavba.png";
import interierove_prace from "../../assets/icons/Interiérové práce.png";
import nakup_dovoz_material from "../../assets/icons/Nákup a dovoz materiálu.png";
import navrhy from "../../assets/icons/Návrhy.png";
import obklady_dlazby from "../../assets/icons/Obklady a dlažby.png";
import stavba_domu from "../../assets/icons/Stavba domu na kľúč.png";
import vykopove_prace from "../../assets/icons/Výkopové práce.png";
import strecha from "../../assets/icons/roof.png";
import stavbar from "../../assets/images/stavbar.JPG";
import stavbar2 from "../../assets/images/stavbar2.JPG";
import papiere from "../../assets/images/papiere.jpg";
import pajko from "../../assets/images/pajko.jpg";
import zrkadlo from "../../assets/images/zrkadlo.JPG";
import domek from "../../assets/images/107.jpg";

export default function Services() {
    const [open, setOpen] = useState(false);

    function arrowIcon() {
        if (!open) {
            return (
                <FontAwesomeIcon icon={faChevronUp} size="3x"/>
            );
        } else {
            return (
                <FontAwesomeIcon icon={faChevronDown} size="3x"/>
            );
        }
    }

    function tabTitle(swaper) {
        if (swaper) {
            return (
                <>
                    <span className="tab-design-build-work">Interierový dizajn</span>
                    <FontAwesomeIcon icon={faCouch} size="1x"/>
                    <br/>
                </>
            );
        } else {
            return (
                <>
                    <span className="tab-design-build-work">Stavebné práce</span>
                    <FontAwesomeIcon icon={faHammer} size="1x"/>
                    <br/>
                </>
            );
        }
    }

    function animation_right(inView, id) {
        if (inView) {
            document.getElementById(id).classList.add("right-animation");
        } else {
            document.getElementById(id).classList.remove("right-animation");
        }
    }

    function animation_left(inView, id) {
        if (inView) {
            document.getElementById(id).classList.add("left-animation");
        } else {
            document.getElementById(id).classList.remove("left-animation");
        }
    }

    function animation_right_slower(inView, id) {
        if (inView) {
            document.getElementById(id).classList.add("right-animation-slower");
        } else {
            document.getElementById(id).classList.remove("right-animation-slower");
        }
    }

    return (
        <>
            <div id="container" className="container-height-auto lighter-theme">
                <div className="vertical-align">
                    <Container>
                        <div id='services'/>
                        <Row className="mt-3">
                            <Col xs={12} md={12} xl={2} className="mt-3">
                                <div className="service-title">Služby</div>
                            </Col>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image mt-2" variant="top" src={drobne_objekty} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Drobné objekty</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image mt-2" variant="top" src={exterierove_prace} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Exteriérové práce</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image mt-2" variant="top" src={hruba_stavba} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Hrubá stavba</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image mt-2" variant="top" src={interierove_prace} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Interiérové práce</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image mt-2" variant="top" src={nakup_dovoz_material} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Nákup a dovoz materiálu</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={0} md={0} xl={2}/>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image mt-2" variant="top" src={vykopove_prace} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Výkopové práce</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image mt-2" variant="top" src={stavba_domu} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Stavba domu na kľúč</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image-navrh" variant="top" src={navrhy} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Návrhy</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image mt-2" variant="top" src={obklady_dlazby} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Obklady a dlažby</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} md={3} xl={2} className="mt-3">
                                <Card style={{ width: '8rem' }} className="card-align">
                                    <div className="card-background">
                                        <Card.Img className="card-image mt-2" variant="top" src={strecha} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title><span className="card-text">Strechy</span></Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br/><br/>
                        <Row>
                            <Col>
                                <p className="service-text">Ak ste niekde počuli „nie, nedá sa“, tak u nás to rozhodne počuť nebudete! Ponúkame vám výhodné služby za výhodnú cenu.
                                    Kliektom zabezpečíme komplexný servis, ale v prípade potreby pomáhame aj s maličkosťami.
                                </p>
                                <br/>
                                <br/>
                            </Col>
                        </Row>
                        <Row>
                            <div className="title">CENA <span className="normal-text">(Cena v € bez DPH)</span></div>
                            <Col>
                                <div className="text-realization mobile-gap">
                                    Každá stavba je jedinečná, preto nás neváhajte <a href='/#contact' style={{textDecoration: 'underline'}}>kontaktovať</a> pre vytvorenie cenovej ponuky
                                </div>
                            </Col>
                            <br/><br/><br/>
                        </Row>
                        <br/>
                        <Row>
                            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3 padding-right-off tabs-design-build-works">
                                <Tab eventKey="profile" title={tabTitle(false)}>
                                    <br/><br/>
                                    <Row>
                                        <div className="title"> ČO DOSTANETE OD NÁS</div>
                                        <Col>
                                            <Row className="service-offer-background">
                                                <Col style={{padding: "0"}} md={6}>
                                                    <InView as="div" onChange={(inView, entry) => animation_left(inView, 'service-photo-left1')}>
                                                        <img id="service-photo-left1" src={realization} alt="Logo" className="image-realization start_animation long-image"/>
                                                    </InView>
                                                </Col>
                                                <Col md={12} xl={6}>
                                                    <div className="offer-text margin-first-image1">
                                                        <InView id="service-text-right1" className="start_animation" as="div" onChange={(inView, entry) => animation_right(inView, 'service-text-right1')}>
                                                            <span>
                                                                Ponúkame realizáciu stavieb akéhokoľvek druhu.
                                                                Na trhu sme už dvadsať rokov, za ktoré sme nazbierali mnoho skúseností.
                                                            </span>
                                                        </InView>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="offer-row">
                                                <Col md={12} xl={6}>
                                                    <div className="offer-text margin-first-image2">
                                                        <InView id="service-text-left1" className="start_animation" as="div" onChange={(inView, entry) => animation_left(inView, 'service-text-left1')}>
                                                            <h2 className="service-subtitle offer-subtitle-margin">Kvalitnú prácu</h2>
                                                            <span>
                                                                    Na základe toho ponúkame kvalitnú prácu za veľmi výhodné ceny.
                                                                    Vieme vyhovieť a prispôsobiť sa požiadavkám zákazníka a preto
                                                                    veríme, že po skúsenosti s nami budete určite spokojní.
                                                            </span>
                                                        </InView>
                                                    </div>
                                                </Col>
                                                <Col style={{padding: "0"}} md={6}>
                                                    <div className="offer-container">
                                                        <InView as="div" className="offer-image"  onChange={(inView, entry) => animation_right_slower(inView, 'service-photo-right2')}>
                                                            <img id="service-photo-right2" src={zrkadlo} alt="Logo" className="offer-image-bigger start_animation long-image"/>
                                                        </InView>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="service-offer-background">
                                                <Col className="width-auto" style={{padding: "0"}} md={6}>
                                                    <InView as="div" onChange={(inView, entry) => animation_left(inView, 'service-photo-left2')}>
                                                        <img id="service-photo-left2" src={stavbar} className="image image-height-carousel start_animation"/>
                                                    </InView>
                                                </Col>
                                                <Col md={12} xl={6}>
                                                    <div className="offer-text margin-first-image3">
                                                        <InView id="service-text-right2" className="start_animation" as="div" onChange={(inView, entry) => animation_right(inView, 'service-text-right2')}>
                                                            <span>
                                                                Po dokončení diela a riadnom predaní a prevzatí diela začína plynúť záručná doba,
                                                                ktorá je u našej spoločnosti 24 mesačná. Aj po dokončení stavby je investorovi naša
                                                                spoločnosť neustále k dispozícii, či už pre riešenie drobných problémov, tak pre
                                                                prípravu ďalších akcií.
                                                            </span>
                                                        </InView>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="offer-row">
                                                <Col md={12} xl={6}>
                                                    <div className="offer-row offer-text margin-first-image4">
                                                        <InView id="service-text-left3" className="start_animation" as="div" onChange={(inView, entry) => animation_left(inView, 'service-text-left3')}>
                                                            <h2 className="service-subtitle offer-subtitle-margin">O nič sa nestaráte</h2>
                                                            <span>
                                                                Po dokončení diela a riadnom predaní a prevzatí diela začína plynúť záručná doba,
                                                                ktorá je u našej spoločnosti 24 mesačná. Aj po dokončení stavby je investorovi naša
                                                                spoločnosť neustále k dispozícii, či už pre riešenie drobných problémov, tak pre
                                                                prípravu ďalších akcií.
                                                            </span>
                                                        </InView>
                                                    </div>
                                                </Col>
                                                <Col md={12} xl={6}>
                                                    <div className="offer-container">
                                                        <InView as="div" className="offer-image" onChange={(inView, entry) => animation_right_slower(inView, 'service-photo-right3')}>
                                                            <img id="service-photo-right3" src={stavbar2} className="offer-image-bigger start_animation long-image"/>
                                                        </InView>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="service-offer-background">
                                                <Col className="width-auto" style={{padding: "0"}} md={6}>
                                                    <InView as="div" onChange={(inView, entry) => animation_left(inView, 'service-photo-left4')}>
                                                        <img id="service-photo-left4" src={papiere} className="image image-height-carousel start_animation"/>
                                                    </InView>
                                                </Col>
                                                <Col md={12} xl={6}>
                                                    <div className="offer-text margin-first-image5">
                                                        <InView id="service-text-right4" className="start_animation" as="div" onChange={(inView, entry) => animation_right(inView, 'service-text-right4')}>
                                                            <span>
                                                                Firma A-Z stavba vlastní vlastné dodávkové vozidlá (do 3,5 tony),
                                                                čo znamená, že u nás nakúpený stavebný materiál Vám dovezieme a
                                                                vyložíme priamo na Vašej stavbe.
                                                            </span>
                                                        </InView>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="offer-row">
                                                <Col md={12} xl={6}>
                                                    <div className="offer-text margin-first-image6">
                                                        <InView id="service-text-left4" className="start_animation" as="div" onChange={(inView, entry) => animation_left(inView, 'service-text-left4')}>
                                                            <h2 className="service-subtitle offer-subtitle-margin">3D Vizualizácie</h2>
                                                            <span>
                                                                Nechajte si u nás vytvoriť 3D vizualizácie aby vás nič neprekvapilo.
                                                                Veľmi vysoký plot, zlý odtieň dlažby, alebo mohutná pergola ? Tomu všetkému
                                                                sa dá predísť v návrhu, ktorý pre vás na mieru pripraví náš návrhár na základe
                                                                vašich potrieb a požiadavok. Následne to s ním viete korigovať, aby výsledok bol
                                                                podľa vašich predstáv.
                                                            </span>
                                                        </InView>
                                                    </div>
                                                </Col>
                                                <Col style={{padding: "0"}} md={12} xl={6}>
                                                    <div className="offer-container">
                                                        <InView as="div" className="offer-image" onChange={(inView, entry) => animation_right_slower(inView, 'service-photo-right4')}>
                                                            <img id="service-photo-right4" src={pajko} className="offer-image-bigger start_animation long-image"/>
                                                        </InView>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="service-offer-background">
                                                <Col style={{padding: "0"}} md={12} xl={6}>
                                                    <InView id="service-photo-left5" as="div" className="start_animation" onChange={(inView, entry) => animation_left(inView, 'service-photo-left5')}>
                                                        <img src={domek} className="image image-height-carousel"/>
                                                    </InView>
                                                </Col>
                                                <Col md={12} xl={6}>
                                                    <div className="offer-text margin-first-image7">
                                                        <InView id="service-text-right5" as="div" className="start_animation" onChange={(inView, entry) => animation_right(inView, 'service-text-right5')}>
                                                            <span>
                                                                Firma A-Z stavba stavba ponúka dopravné služby s dodávkovým vozom
                                                                nielen pre svojich klientov, ale aj pre ostatných záujemcov o
                                                                prepravu rôzneho druhu tovaru.
                                                            </span>
                                                        </InView>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
