// css files
import '../App.css'
import './HeroSection.css'

// components
import React from "react";

// icons, images, videos
import previewVideo from '../assets/videos/video1.mp4'
import homeImg0 from '../assets/images/logo_ujo.jpg'
import homeImg2 from '../assets/images/home/008.jpg'
import homeImg3 from '../assets/images/home/007.png'

import homeImg5 from '../assets/images/home/002.png'
import homeImg6 from '../assets/images/home/003.png'
import homeImg7 from '../assets/images/home/001.png'
import homeImg8 from '../assets/images/home/004.png'
import homeImg9 from '../assets/images/home/005.jpg'

import {Carousel} from "react-bootstrap";

function HeroSection() {

    window.onload = function() {
        setTimeout(function(){
            let attr = document.getElementById("first-image").className.split(" ");
            document.getElementById("first-image").className = attr[0];
        },4000);
    }
    // <video src={previewVideo} autoPlay loop muted/>
    return (
        <div className='hero-container'>
            <div id='home'/>
            <Carousel
                fade={true}
                id="homeC"
                pause={false}
                keyboard={false}
                controls={false}
                >

                <Carousel.Item interval={5000}>
                    <img id="first-image" className="home-img img-blur" src={homeImg0}/>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img className="home-img" src={homeImg2}/>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img className="home-img" src={homeImg3}/>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img className="home-img" src={homeImg5}/>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img className="home-img" src={homeImg6}/>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img className="home-img" src={homeImg7}/>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img className="home-img" src={homeImg8}/>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img className="home-img" src={homeImg9}/>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default HeroSection;
