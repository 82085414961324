// css files
import './Images.css';
import 'bootstrap/dist/css/bootstrap.css';

// components
import React, { useState } from 'react';
import {Carousel, Modal} from "react-bootstrap";

export default class extends React.Component  {
    constructor(props) {
        super(props);
        this.photo=props.photo;
        this.message=props.message;
        this.author=props.author;
        this.float=props.float;
        this.overlay=props.overlay;
        this.carousel1=props.carousel1;
        this.carousel2=props.carousel2;
        this.carousel3=props.carousel3;
        this.carousel4=props.carousel4;
        this.carousel5=props.carousel5;
        this.carousel6=props.carousel6;
        this.carousel7=props.carousel7;
    }

    render() {
        return (
            <TooltipImage
                photo={this.photo}
                message={this.message}
                author={this.author}
                overlay={this.overlay}
                carousel1={this.carousel1}
                carousel2={this.carousel2}
                carousel3={this.carousel3}
                carousel4={this.carousel4}
                carousel5={this.carousel5}
                carousel6={this.carousel6}
                carousel7={this.carousel7}
                float={this.float}/>
        );
    }
}

function TooltipImage(props) {
    const [show, setShow] = useState(false);
    const interval = 2000;

    return (
        <>
            <div className="image-container" onClick={() => setShow(true)}>
                <img src={props.photo} alt="Avatar" className="image image-height"/>
                <div className={props.overlay}>
                    <span className={props.float}>
                        <span className="text">{props.message}</span><br/>
                        <span className="text-smaller">{props.author}</span>
                    </span>
                </div>
            </div>

            <Modal
                id="modal-images"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body>
                    <Carousel>
                        <Carousel.Item interval={interval}>
                            <img src={props.carousel1} alt="Avatar" className="image image-height-carousel"/>
                        </Carousel.Item>
                        <Carousel.Item interval={interval}>
                            <img src={props.carousel2} alt="Avatar" className="image image-height-carousel"/>
                        </Carousel.Item>
                        <Carousel.Item interval={interval}>
                            <img src={props.carousel3} alt="Avatar" className="image image-height-carousel"/>
                        </Carousel.Item>
                        <Carousel.Item interval={interval}>
                            <img src={props.carousel4} alt="Avatar" className="image image-height-carousel"/>
                        </Carousel.Item>
                        <Carousel.Item interval={interval}>
                            <img src={props.carousel5} alt="Avatar" className="image image-height-carousel"/>
                        </Carousel.Item>
                        <Carousel.Item interval={interval}>
                            <img src={props.carousel6} alt="Avatar" className="image image-height-carousel"/>
                        </Carousel.Item>
                        <Carousel.Item interval={interval}>
                            <img src={props.carousel7} alt="Avatar" className="image image-height-carousel"/>
                        </Carousel.Item>
                    </Carousel>
                </Modal.Body>
            </Modal>
        </>
    );
}
