// css files
import "./Footer.css"

// components
import React from "react";
import {Col, Container, ModalFooter, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faHouseUser, faHammer, faPhone} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <ModalFooter className="footer-theme">
            <Container fluid className="text-md-left">
                <Row>
                    <Col className="text-center">
                        <h6 className="title footer-title text-center">ZAUJALI SME VÁS? KONTAKTUJTE NÁS!</h6>
                    </Col>
                    <Col className="text-center follow-disable">
                        <h5 className="title footer-title">NEZABUDNITE NÁS SLEDOVAŤ</h5>
                    </Col>
                </Row>
                <div className="title"/>
                <Row className="justify-content-center">
                    <Col lg={12} xl={6}>
                        <div className="justify-content-center footer-text-styling ml-15">
                            <Row className="center-contact">
                                <Col sm="0" md={1}/>
                                <Col xs={12} sm={12} md={6}>
                                    <FontAwesomeIcon icon={faHammer} className="mr-10"/>A-Z STAVBA s.r.o.<br/>
                                    <FontAwesomeIcon icon={faHouseUser} className="mr-10"/>Dubová 2714/6, 08501 Bardejov<br/>
                                    <FontAwesomeIcon icon={faEnvelope} className="mr-10"/>
                                    <a href="mailto:azstavba@centrum.sk">azstavba@centrum.sk</a>
                                </Col>
                                <Col xs={12} sm={12} md={5}>
                                    <FontAwesomeIcon icon={faPhone} className="mr-10"/>
                                    <span className="mr-10">Stavebné práce:</span>
                                    <a href="tel:+421 904 351 410">
                                        +421 904 351 410
                                    </a><br/>
                                    <FontAwesomeIcon icon={faPhone} className="mr-10"/>
                                    <span className="mr-10">Interiérový design:</span>
                                    <a id="test3" href="tel:+421 948 171 456">
                                        +421 948 171 456
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={12} xl={6}>
                        <Row className="justify-content-center align-icons">
                            <ul className="text-center">
                                <a onClick={() => openInNewTab('https://www.facebook.com/A-Z-Stavba-108127138093991')}>
                                    <FontAwesomeIcon icon={faInstagram} size="2x" className="icon-border"/>
                                </a>
                                <a onClick={() => openInNewTab('https://www.instagram.com/az_stavba_/')}>
                                    <FontAwesomeIcon icon={faFacebook} size="2x" className="icon-border"/>
                                </a>
                            </ul>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-3 foot-copyright">
                        &copy; {new Date().getFullYear()} Copyright: <a href="mailto:maros.geffert@gmail.com" className="footer-link"> Maroš Geffert </a>
                    </Col>
                </Row>
            </Container>
        </ModalFooter>
    );
}

export default Footer;
