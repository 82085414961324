// css files
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// components
import React, { } from "react";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// own components
import Navbar from "./components/Navbar";
import Home from './components/pages/Home';
import HowOrder from "./components/pages/HowOrder";
import Services from "./components/pages/Services";
import Cooperation from "./components/pages/Cooperation";
import Contact from "./components/pages/Contact";

// icons
import {
    faFilePdf, faFileImage, faArrowCircleUp, faComments, faEnvelope, faGlobeAmericas, faMap, faPaperPlane,
    faUser, faFileAlt
} from '@fortawesome/free-solid-svg-icons'
import About from "./components/pages/About";
library.add(
    fas, faFilePdf, faFileImage, faPaperPlane, faArrowCircleUp, faComments, faEnvelope, faGlobeAmericas,
    faMap, faPaperPlane, faUser, faFileAlt
)

function App() {
    return (
        <>
            <Router>
                <Navbar />
                <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/#about' exact component={About} />
                    <Route path='/#services' exact component={Services} />
                    <Route path='/#order' exact component={HowOrder} />
                    <Route path='/#cooperation' exact component={Cooperation} />
                    <Route path='/#contact' exact component={Contact} />
                </Switch>
            </Router>
        </>
    )
}

export default App;
