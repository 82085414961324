import React from "react";
import '../../App.css';
import HeroSection from '../HeroSection';
import About from '../pages/About';
import HowOrder from "./HowOrder";
import Services from "./Services";
import Realizations from "./Realizations";
import Cooperation from "./Cooperation";
import Contact from "./Contact";
import Popup from "../order_components/Popup";
import Footer from "../Footer";

function Home() {
    return (
        <>
            <HeroSection/>
            <About/>
            <Services/>
            <Realizations/>
            <Cooperation/>
            <Contact/>
            <Footer/>
        </>
    );
}

export default Home;