// css files
import '../../App.css';
import '../Realizations.css';

// components
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {Button, Col, Collapse, Container, Row, Tab, Tabs} from "react-bootstrap";
import {faChevronDown, faChevronUp, faCircle, faCouch, faHammer,} from "@fortawesome/free-solid-svg-icons";

// own components
import realization from "../../assets/images/realizacie/progress/003.jpg";
import realization2 from "../../assets/images/realizacie/domy/007.jpg";
import Calculator from "../Calculator";
import ImageModal9 from "./realizations/ImageModal9";
import detska_izba_xxx1_1 from "../../assets/images/realizations/detska_izba_xxx1/211.jpg";
import detska_izba_xxx1_2 from "../../assets/images/realizations/detska_izba_xxx1/215.jpg";
import detska_izba_xxx1_3 from "../../assets/images/realizations/detska_izba_xxx1/219.jpg";
import detska_izba_xxx1_4 from "../../assets/images/realizations/detska_izba_xxx1/220.jpg";
import detska_izba_xxx1_5 from "../../assets/images/realizations/detska_izba_xxx1/221.jpg";
import detska_izba_xxx1_6 from "../../assets/images/realizations/detska_izba_xxx1/222.jpg";
import detska_izba_xxx1_7 from "../../assets/images/realizations/detska_izba_xxx1/223.jpg";
import detska_izba_xxx1_8 from "../../assets/images/realizations/detska_izba_xxx1/224.jpg";
import detska_izba_xxx1_9 from "../../assets/images/realizations/detska_izba_xxx1/225.jpg";
import ImageModal7 from "./realizations/ImageModal7";
import detska_izba_xxx2_1 from "../../assets/images/realizations/detska_izba_xxx2/102.jpg";
import detska_izba_xxx2_2 from "../../assets/images/realizations/detska_izba_xxx2/103.jpg";
import detska_izba_xxx2_3 from "../../assets/images/realizations/detska_izba_xxx2/106.jpg";
import detska_izba_xxx2_4 from "../../assets/images/realizations/detska_izba_xxx2/107.jpg";
import detska_izba_xxx2_5 from "../../assets/images/realizations/detska_izba_xxx2/108.jpg";
import detska_izba_xxx2_6 from "../../assets/images/realizations/detska_izba_xxx2/109.jpg";
import detska_izba_xxx2_7 from "../../assets/images/realizations/detska_izba_xxx2/110.jpg";
import detska_izba_xxx3_1 from "../../assets/images/realizations/detska_izba_xxx3/018.jpg";
import detska_izba_xxx3_2 from "../../assets/images/realizations/detska_izba_xxx3/019.jpg";
import detska_izba_xxx3_3 from "../../assets/images/realizations/detska_izba_xxx3/020.jpg";
import detska_izba_xxx3_4 from "../../assets/images/realizations/detska_izba_xxx3/021.jpg";
import detska_izba_xxx3_5 from "../../assets/images/realizations/detska_izba_xxx3/023.jpg";
import detska_izba_xxx3_6 from "../../assets/images/realizations/detska_izba_xxx3/024.jpg";
import detska_izba_xxx3_7 from "../../assets/images/realizations/detska_izba_xxx3/025.jpg";
import dom_masica1 from "../../assets/images/realizations/dom_masica/004.jpg";
import dom_masica2 from "../../assets/images/realizations/dom_masica/005.jpg";
import dom_masica3 from "../../assets/images/realizations/dom_masica/007.jpg";
import dom_masica4 from "../../assets/images/realizations/dom_masica/009.jpg";
import dom_masica5 from "../../assets/images/realizations/dom_masica/013.jpg";
import dom_masica6 from "../../assets/images/realizations/dom_masica/014.jpg";
import dom_masica7 from "../../assets/images/realizations/dom_masica/015.jpg";
import dom_masica8 from "../../assets/images/realizations/dom_masica/016.jpg";
import dom_masica9 from "../../assets/images/realizations/dom_masica/021.jpg";
import ImageModal4 from "./realizations/ImageModal4";
import dom_krok1 from "../../assets/images/realizations/dom_krok/001.jpg";
import dom_krok2 from "../../assets/images/realizations/dom_krok/002.jpg";
import dom_krok3 from "../../assets/images/realizations/dom_krok/003.jpg";
import dom_krok4 from "../../assets/images/realizations/dom_krok/004.jpg";
import detska_izba_xxx4_1 from "../../assets/images/realizations/detska_izba_xxx4/018.jpg";
import detska_izba_xxx4_2 from "../../assets/images/realizations/detska_izba_xxx4/019.jpg";
import detska_izba_xxx4_3 from "../../assets/images/realizations/detska_izba_xxx4/020.jpg";
import detska_izba_xxx4_4 from "../../assets/images/realizations/detska_izba_xxx4/021.jpg";
import detska_izba_xxx4_5 from "../../assets/images/realizations/detska_izba_xxx4/023.jpg";
import detska_izba_xxx4_6 from "../../assets/images/realizations/detska_izba_xxx4/024.jpg";
import detska_izba_xxx4_7 from "../../assets/images/realizations/detska_izba_xxx4/025.jpg";
import ImageModal5 from "./realizations/ImageModal5";
import detska_izba_xxx5_1 from "../../assets/images/realizations/detska_izba_xxx5/102.jpg";
import detska_izba_xxx5_2 from "../../assets/images/realizations/detska_izba_xxx5/105.jpg";
import detska_izba_xxx5_3 from "../../assets/images/realizations/detska_izba_xxx5/107.jpg";
import detska_izba_xxx5_4 from "../../assets/images/realizations/detska_izba_xxx5/108.jpg";
import detska_izba_xxx5_5 from "../../assets/images/realizations/detska_izba_xxx5/110.jpg";
import spalna_xxx1_1 from "../../assets/images/realizations/spalna_xxx1/019.png";
import spalna_xxx1_2 from "../../assets/images/realizations/spalna_xxx1/025.png";
import spalna_xxx1_3 from "../../assets/images/realizations/spalna_xxx1/113.png";
import spalna_xxx1_4 from "../../assets/images/realizations/spalna_xxx1/114.png";
import spalna_xxx1_5 from "../../assets/images/realizations/spalna_xxx1/118.png";
import spalna_xxx1_6 from "../../assets/images/realizations/spalna_xxx1/119.png";
import spalna_xxx1_7 from "../../assets/images/realizations/spalna_xxx1/120.png";
import ImageModal2 from "./realizations/ImageModal2";
import kuchyna_xxx1 from "../../assets/images/realizations/kuchyna_xxx1/009.jpg";
import kuchyna_xxx2 from "../../assets/images/realizations/kuchyna_xxx1/007.jpg";
import obyvacka_xxx1_1 from "../../assets/images/realizations/obyvacka_xxx1/001.jpg";
import obyvacka_xxx1_2 from "../../assets/images/realizations/obyvacka_xxx1/004.jpg";
import obyvacka_xxx1_3 from "../../assets/images/realizations/obyvacka_xxx1/009.jpg";
import obyvacka_xxx1_4 from "../../assets/images/realizations/obyvacka_xxx1/010.jpg";
import obyvacka_xxx1_5 from "../../assets/images/realizations/obyvacka_xxx1/013.jpg";
import obyvacka_xxx1_6 from "../../assets/images/realizations/obyvacka_xxx1/014.jpg";
import obyvacka_xxx1_7 from "../../assets/images/realizations/obyvacka_xxx1/015.jpg";
import ImageModal13 from "./realizations/ImageModal13";
import dom1 from "../../assets/images/realizacie/domy/001.jpg";
import dom2 from "../../assets/images/realizacie/domy/002.jpg";
import dom3 from "../../assets/images/realizacie/domy/003.jpg";
import dom4 from "../../assets/images/realizacie/domy/004.jpg";
import dom5 from "../../assets/images/realizacie/domy/005.jpg";
import dom6 from "../../assets/images/realizacie/domy/006.jpg";
import dom7 from "../../assets/images/realizacie/domy/007.jpg";
import dom8 from "../../assets/images/realizacie/domy/008.jpg";
import dom9 from "../../assets/images/realizacie/domy/009.jpg";
import dom10 from "../../assets/images/realizacie/domy/010.jpg";
import dom11 from "../../assets/images/realizacie/domy/011.jpg";
import ImageModal8 from "./realizations/ImageModal8";
import doplnky1 from "../../assets/images/realizacie/doplnky/001.jpg";
import doplnky2 from "../../assets/images/realizacie/doplnky/002.jpg";
import doplnky3 from "../../assets/images/realizacie/doplnky/003.jpg";
import doplnky4 from "../../assets/images/realizacie/doplnky/004.jpg";
import doplnky5 from "../../assets/images/realizacie/doplnky/005.jpg";
import doplnky6 from "../../assets/images/realizacie/doplnky/006.jpg";
import doplnky7 from "../../assets/images/realizacie/doplnky/007.jpg";
import doplnky8 from "../../assets/images/realizacie/doplnky/008.jpg";
import exterier1 from "../../assets/images/realizacie/exterier/001.jpg";
import exterier2 from "../../assets/images/realizacie/exterier/002.jpg";
import exterier3 from "../../assets/images/realizacie/exterier/003.jpg";
import exterier4 from "../../assets/images/realizacie/exterier/004.jpg";
import exterier5 from "../../assets/images/realizacie/exterier/005.jpg";
import exterier6 from "../../assets/images/realizacie/exterier/006.jpg";
import exterier7 from "../../assets/images/realizacie/exterier/007.jpg";
import exterier8 from "../../assets/images/realizacie/exterier/008.jpg";
import ImageModal11 from "./realizations/ImageModal11";
import kupelne1 from "../../assets/images/realizacie/kupelne/001.jpg";
import kupelne2 from "../../assets/images/realizacie/kupelne/002.jpg";
import kupelne3 from "../../assets/images/realizacie/kupelne/003.jpg";
import kupelne4 from "../../assets/images/realizacie/kupelne/004.jpg";
import kupelne5 from "../../assets/images/realizacie/kupelne/005.jpg";
import kupelne6 from "../../assets/images/realizacie/kupelne/006.jpg";
import kupelne7 from "../../assets/images/realizacie/kupelne/007.jpg";
import kupelne8 from "../../assets/images/realizacie/kupelne/008.jpg";
import kupelne9 from "../../assets/images/realizacie/kupelne/009.jpg";
import kupelne10 from "../../assets/images/realizacie/kupelne/010.jpg";
import kupelne11 from "../../assets/images/realizacie/kupelne/011.jpg";
import ImageModal3 from "./realizations/ImageModal3";
import obyvacka1 from "../../assets/images/realizacie/obyvacka/001.jpg";
import obyvacka2 from "../../assets/images/realizacie/obyvacka/002.jpg";
import obyvacka3 from "../../assets/images/realizacie/obyvacka/003.jpg";
import progress1 from "../../assets/images/realizacie/progress/001.jpg";
import progress2 from "../../assets/images/realizacie/progress/002.jpg";
import progress3 from "../../assets/images/realizacie/progress/003.jpg";
import progress4 from "../../assets/images/realizacie/progress/004.jpg";
import progress5 from "../../assets/images/realizacie/progress/005.jpg";
import progress6 from "../../assets/images/realizacie/progress/006.jpg";
import progress7 from "../../assets/images/realizacie/progress/007.jpg";
import progress8 from "../../assets/images/realizacie/progress/008.jpg";
import progress9 from "../../assets/images/realizacie/progress/009.jpg";
import progress10 from "../../assets/images/realizacie/progress/010.jpg";
import progress11 from "../../assets/images/realizacie/progress/011.jpg";
import progress12 from "../../assets/images/realizacie/progress/012.jpg";
import progress13 from "../../assets/images/realizacie/progress/013.jpg";

export default function Services() {
    const [open, setOpen] = useState(false);

    function arrowIcon() {
        if (!open) {
            return (
                <FontAwesomeIcon icon={faChevronUp} size="3x"/>
            );
        } else {
            return (
                <FontAwesomeIcon icon={faChevronDown} size="3x"/>
            );
        }
    }

    function tabTitle(swaper) {
        if (swaper) {
            return (
                <>
                    <span className="tab-design-build-work">NÁVRHY</span>
                    <FontAwesomeIcon icon={faCouch} size="1x"/>
                    <br/>
                </>
            );
        } else {
            return (
                <>
                    <span className="tab-design-build-work">REALIZÁCIE</span>
                    <FontAwesomeIcon icon={faHammer} size="1x"/>
                    <br/>
                </>
            );
        }
    }

    return (
        <>
            <div id="container-realizations" className="container-height-auto lighter-theme">
                <div className="vertical-align">
                    <Container>
                        <div id='realizations'/>
                        <Row>
                            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3 padding-right-off tabs-design-build-works">
                                <Tab eventKey="profile" title={tabTitle(false)}>
                                    <br/><br/><br/>
                                    <div className="container-realizations">
                                        <Row>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal11
                                                    carousel1={dom1}
                                                    carousel2={dom2}
                                                    carousel3={dom3}
                                                    carousel4={dom4}
                                                    carousel5={dom5}
                                                    carousel6={dom6}
                                                    carousel7={dom7}
                                                    carousel8={dom8}
                                                    carousel9={dom9}
                                                    carousel10={dom10}
                                                    carousel11={dom11}
                                                    photo={dom1}
                                                    message="Domy"
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal8
                                                    carousel1={doplnky1}
                                                    carousel2={doplnky2}
                                                    carousel3={doplnky3}
                                                    carousel4={doplnky4}
                                                    carousel5={doplnky5}
                                                    carousel6={doplnky6}
                                                    carousel7={doplnky7}
                                                    carousel8={doplnky8}
                                                    photo={doplnky1}
                                                    message="Doplnky"
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal8
                                                    carousel1={exterier1}
                                                    carousel2={exterier2}
                                                    carousel3={exterier3}
                                                    carousel4={exterier4}
                                                    carousel5={exterier5}
                                                    carousel6={exterier6}
                                                    carousel7={exterier7}
                                                    carousel8={exterier8}
                                                    photo={exterier1}
                                                    message="Exteriér"
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal11
                                                    carousel1={kupelne1}
                                                    carousel2={kupelne2}
                                                    carousel3={kupelne3}
                                                    carousel4={kupelne4}
                                                    carousel5={kupelne5}
                                                    carousel6={kupelne6}
                                                    carousel7={kupelne7}
                                                    carousel8={kupelne8}
                                                    carousel9={kupelne9}
                                                    carousel10={kupelne10}
                                                    carousel11={kupelne11}
                                                    photo={kupelne5}
                                                    message="Kúpelne"
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal3
                                                    carousel1={obyvacka1}
                                                    carousel2={obyvacka2}
                                                    carousel3={obyvacka3}
                                                    photo={obyvacka1}
                                                    author=""
                                                    message="Obývačky"
                                                    overlay="overlay"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal13
                                                    carousel1={progress1}
                                                    carousel2={progress2}
                                                    carousel3={progress3}
                                                    carousel4={progress4}
                                                    carousel5={progress5}
                                                    carousel6={progress6}
                                                    carousel7={progress7}
                                                    carousel8={progress8}
                                                    carousel9={progress9}
                                                    carousel10={progress10}
                                                    carousel11={progress11}
                                                    carousel12={progress12}
                                                    carousel13={progress13}
                                                    photo={progress1}
                                                    message="Progres"
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab>
                                <Tab eventKey="home" title={tabTitle(true)} >
                                    <br/><br/><br/>
                                    <div className="container-realizations">
                                        <Row>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal9
                                                    carousel1={detska_izba_xxx1_1}
                                                    carousel2={detska_izba_xxx1_2}
                                                    carousel3={detska_izba_xxx1_3}
                                                    carousel4={detska_izba_xxx1_4}
                                                    carousel5={detska_izba_xxx1_5}
                                                    carousel6={detska_izba_xxx1_6}
                                                    carousel7={detska_izba_xxx1_7}
                                                    carousel8={detska_izba_xxx1_8}
                                                    carousel9={detska_izba_xxx1_9}
                                                    photo={detska_izba_xxx1_1}
                                                    message=""
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal7
                                                    carousel1={detska_izba_xxx2_1}
                                                    carousel2={detska_izba_xxx2_2}
                                                    carousel3={detska_izba_xxx2_3}
                                                    carousel4={detska_izba_xxx2_4}
                                                    carousel5={detska_izba_xxx2_5}
                                                    carousel6={detska_izba_xxx2_6}
                                                    carousel7={detska_izba_xxx2_7}
                                                    photo={detska_izba_xxx2_1}
                                                    message=""
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal7
                                                    carousel1={detska_izba_xxx3_1}
                                                    carousel2={detska_izba_xxx3_2}
                                                    carousel3={detska_izba_xxx3_3}
                                                    carousel4={detska_izba_xxx3_4}
                                                    carousel5={detska_izba_xxx3_5}
                                                    carousel6={detska_izba_xxx3_6}
                                                    carousel7={detska_izba_xxx3_7}
                                                    photo={detska_izba_xxx3_1}
                                                    message=""
                                                    author=""
                                                    overlay="overlay-right"
                                                    float="float-right"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal9
                                                    carousel1={dom_masica1}
                                                    carousel2={dom_masica2}
                                                    carousel3={dom_masica3}
                                                    carousel4={dom_masica4}
                                                    carousel5={dom_masica5}
                                                    carousel6={dom_masica6}
                                                    carousel7={dom_masica7}
                                                    carousel8={dom_masica8}
                                                    carousel9={dom_masica9}
                                                    photo={dom_masica1}
                                                    message=""
                                                    author=""
                                                    overlay="overlay-right"
                                                    float="float-right"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal4
                                                    carousel1={dom_krok1}
                                                    carousel2={dom_krok2}
                                                    carousel3={dom_krok3}
                                                    carousel4={dom_krok4}
                                                    photo={dom_krok1}
                                                    message=""
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal7
                                                    carousel1={detska_izba_xxx4_1}
                                                    carousel2={detska_izba_xxx4_2}
                                                    carousel3={detska_izba_xxx4_3}
                                                    carousel4={detska_izba_xxx4_4}
                                                    carousel5={detska_izba_xxx4_5}
                                                    carousel6={detska_izba_xxx4_6}
                                                    carousel7={detska_izba_xxx4_7}
                                                    photo={detska_izba_xxx4_1}
                                                    message=""
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal5
                                                    carousel1={detska_izba_xxx5_1}
                                                    carousel2={detska_izba_xxx5_2}
                                                    carousel3={detska_izba_xxx5_3}
                                                    carousel4={detska_izba_xxx5_4}
                                                    carousel5={detska_izba_xxx5_5}
                                                    photo={detska_izba_xxx5_1}
                                                    message=""
                                                    author=""
                                                    overlay="overlay-right"
                                                    float="float-right"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal7
                                                    carousel1={spalna_xxx1_1}
                                                    carousel2={spalna_xxx1_2}
                                                    carousel3={spalna_xxx1_3}
                                                    carousel4={spalna_xxx1_4}
                                                    carousel5={spalna_xxx1_5}
                                                    carousel6={spalna_xxx1_6}
                                                    carousel7={spalna_xxx1_7}
                                                    photo={spalna_xxx1_1}
                                                    message=""
                                                    author=""
                                                    float="float-right"
                                                    overlay="overlay-right"/>
                                            </Col>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal2
                                                    carousel1={kuchyna_xxx1}
                                                    carousel2={kuchyna_xxx2}
                                                    photo={kuchyna_xxx1}
                                                    message=""
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4} className="image-grid">
                                                <ImageModal7
                                                    carousel1={obyvacka_xxx1_1}
                                                    carousel2={obyvacka_xxx1_2}
                                                    carousel3={obyvacka_xxx1_3}
                                                    carousel4={obyvacka_xxx1_4}
                                                    carousel5={obyvacka_xxx1_5}
                                                    carousel6={obyvacka_xxx1_6}
                                                    carousel7={obyvacka_xxx1_7}
                                                    photo={obyvacka_xxx1_1}
                                                    message=""
                                                    author=""
                                                    overlay="overlay"/>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
